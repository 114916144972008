<template>
    <div
        :class="{
            'site-title-text sub-title': true,
            'main-entity': firstEntity,
            'sub-child':
                firstEntity || (index < 2 && !(index == 1 && annotationsView)),
        }"
        :style="childEntityStyles(index)"
    >
        <div class="stitle-text profile-avtar">
            <div class="avtar-img">
                <!--Entity Image-->
                <img
                    class="img-fluid"
                    :src="entity.mainEntityInfo.image"
                    :alt="
                        entity.mainEntityInfo.resourceLabel ??
                        entity.mainEntityInfo.resource
                    "
                />
            </div>
            <div class="profile-details">
                <!--Entity Title-->
                <h3
                    :class="{
                        'two-line-ellipsis': true,
                        'sub-child':
                            firstEntity ||
                            (index < 2 && !(index == 1 && annotationsView)),
                        'auto-width': !firstEntity && index > 1,
                    }"
                >
                    {{
                        entity.mainEntityInfo.resourceLabel
                            ? entity.mainEntityInfo.resourceLabel
                            : entity.mainEntityInfo.resource
                    }}
                </h3>
                
                <!--Entity Type-->
                <span
                    :class="{
                        'sub-child':
                            firstEntity ||
                            (index < 2 && !(index == 1 && annotationsView)),
                    }"
                    >{{ entity.mainEntityInfo.typeLabel }}</span
                >
            </div>
        </div>
        <div
            class="icon-btn-group"
            v-show="
                firstEntity || (index < 2 && !(index == 1 && annotationsView))
            "
        >
            <!--Toggle details dropdown button-->
            <a
                href="javascript:void(0)"
                role="button"
                aria-label="Toggle main entity details"
                :aria-expanded="showDetailsIndex == index"
                title="Toggle main entity details"
                :class="{
                    'panel-button icon-btn': true,
                    'border-btn': showDetailsIndex == index,
                }"
                v-on:click="($parent as any).handleToggleOfDetails(index, true)"
            >
                Details
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        firstEntity: Boolean,
        entity: Object,
        index: Number,
        parsedEntityInfo: Array,
        showDetailsIndex: Number,
        annotationsView: Boolean,
    },
})
export default class EntityBanner extends Vue {
    firstEntity!: boolean;
    entity!: any;
    index!: number;
    parsedEntityInfo!: any;
    showDetailsIndex!: number;
    annotationsView!: boolean;

    // Add styling to the child entities
    childEntityStyles(index: number) {
        if (this.firstEntity) {
            return {};
        }

        const paddingLeft = 5 * (index + 1) + "px";
        const borderLeft =
            index === this.parsedEntityInfo[0].children?.length - 1
                ? "4px solid #107386"
                : "";
        return {
            paddingLeft,
            borderLeft,
        };
    }
}
</script>
<style>
.icon-btn-group .panel-button {
    margin-left: 5px;
    color: #ffffff;
    font-size: 14px;
    padding: 4px 8px;
}

.icon-btn-group .panel-button:hover {
    text-decoration: none;
}

.icon-btn-group {
    display: flex;
    align-items: center;
}

.icon-btn.border-btn {
    border-color: #87b9c3;
}

.icon-btn.border-btn img {
    width: 20px;
}

.site-title-text {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    margin-top: 40px;
}

.site-title-text.sub-title.sub-child {
    background: #083943;
    align-items: flex-end;
    border-radius: 4px;
}

.profile-avtar {
    position: relative;
    display: flex;
    align-items: center;
}

.profile-avtar .avtar-img {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
}

.profile-avtar .avtar-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.stitle-text {
    position: relative;
    flex: 1;
    display: flex;
}

.stitle-text span {
    font-size: 14px;
    display: block;
    color: #ffffff;
}

.stitle-text h3 {
    font-size: 16px;
    display: inline-block;
    color: #ffffff;
    margin: 5px 0 0 0;
    font-weight: 600;
}

.sub-title .stitle-text span {
    color: #525455;
    font-weight: 300;
}

.sub-title .stitle-text h3 {
    color: #083943;
}

.sub-title {
    width: auto;
    position: relative;
    margin-top: 0px;
    padding: 12px 8px;
    margin-bottom: 0;
    background-color: #e7f1f3;
    overflow: hidden;
}

.sub-title.active {
    padding-left: 15px;
}

.sub-title.active:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #107386;
    left: 0;
    top: 0;
}

.sub-child {
    width: auto;
    color: #fff !important;
}

.auto-width {
    width: auto !important;
}

.two-line-ellipsis {
    display: -webkit-box !important;
    width: 100% !important;
    max-height: 40px;
    margin: 0 auto;
    line-height: 18px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}
</style>
