export const translations = {
    en: {
        EntitySelectView: {
            back: "Back",
            results_found: "results found.",
            stop_scan: "Stop scan",
            modified_settings: "Modified settings",
            selected: "Selected",
            entity_types: "Entity types",
            datasets: "Datasets",
            hover_on_a: "Select a highlight to view matches",
            select_matching_entity: "Select a matching entity below.",
            entities_matching: "entities found matching",
            all_types: "All types",
            connected_entities: "Connected entities",
            view_documentation: "View documentation",
            birth: "Birth",
            death: "Death",
            pursuits: "Pursuits",
            works: "Works",
            creators: "Creators",
            types: "Categories",
            createDate: "Created",
            bornHere: "Birthplace",
            diedHere: "Deathplace",
            members: "Members",
            groups: "Membership",
        },
        EntityInfoView: {
            back: "Back",
            back_to_categories: "Back to categories",
            connecting_entities: "Connecting Entities",
            outbound_properties: "Outbound Properties"
        },
        CategorySelection: {
            filter_by_category: "Filter by category",
        },
        EntityDetailsDropdown: {
            summary: "Summary",
            dates: "Dates",
            birth: "Birth",
            death: "Death",
            created: "Created",
            dataset: "Dataset",
            identifier: "Identifier",
            view_more: "View more details",
            Pursuits: "Pursuits",
            Attributes: "Attributes",
            Creator_of: "Creator of",
            Birthplace_of: "Birthplace of",
            Deathplace_of: "Deathplace of",
            Categories: "Categories",
            Members: "Members",
            Membership: "Membership",
            Published: "Published",
            Creators: "Creators"
        },
        EntityList: {
            sort_by: "Sort By",
            alphabetical: "Alphabetical",
        },
        EntityResourceCard: {
            connection: "Connection",
            predicate: "Predicate",
        },
        EntityTimeline: {
            sort_by: "Sort By",
            newest_to_oldest: "Newest to oldest",
            oldest_to_newest: "Oldest to newest",
            undated: "Undated",
        },
        EntityViewSelector: {
            list_view: "List view",
            timeline_view: "Timeline view",
        },
        ResearchSpaceButton: {
            view_resource_on: "View resource on ResearchSpace",
        },
        SidePanelHeader: {
            context_explorer: "Context Explorer",
        },
        ThirdLevelTimeline: {
            viewing: "Viewing",
        },
        ViewMoreDetailsView: {
            back: "Back",
            properties: "Properties",
            type: "Type",
            identifier: "Identifier",
            sources: "Sources",
        },
    },
    fr: {
        EntitySelectView: {
            back: "Retour",
            results_found: "résultats trouvés.",
            stop_scan: "Arrêter le balayage",
            modified_settings: "Paramètres modifiés",
            selected: "Sélectionné",
            entity_types: "Types d'entités",
            datasets: "Jeux de données",
            hover_on_a:
                "Sélectionnez une partie mise en évidence pour voir les correspondances.",
            select_matching_entity: "Sélectionnez une entité correspondante ci-dessous.",
            entities_matching: "entités trouvées correspondant",
            all_types: "Tous types",
            connected_entities: "Entités connectées",
            view_documentation: "Voir documentation",
            birth: "Naissance",
            death: "Décès",
            pursuits: "Activités",
            works: "Œuvres",
            creators: "Créateurs",
            types: "Catégories",
            createDate: "Créé",
            bornHere: "Lieu de naissance",
            diedHere: "Lieu de mort",
            members: "Membres",
            groups: "l'Appartenance",
        },
        EntityInfoView: {
            back: "Retour",
            back_to_categories: "Retour aux catégories",
            connecting_entities: "Entités de connexion",
            outbound_properties: "Propriétés sortantes",
        },
        CategorySelection: {
            filter_by_category: "Filtrer par catégorie",
        },
        EntityDetailsDropdown: {
            summary: "Résumé",
            dates: "Dates",
            birth: "Naissance",
            death: "Décès",
            created: "Créé",
            dataset: "Jeu de données",
            identifier: "Identifiant",
            view_more: "Voir plus de détails",
            Pursuits: "Poursuites",
            Attributes: "Attributs",
            Creator_of: "Créateur de",
            Birthplace_of: "Lieu de naissance de",
            Deathplace_of: "Lieu de décès de",
            Categories: "Catégories",
            Members: "Membres",
            Membership: "Adhésion",
            Published: "Publié",
            Creators: "Créateurs"
        },
        EntityList: {
            sort_by: "Trier par",
            alphabetical: "Alphabétique",
        },
        EntityResourceCard: {
            connection: "Lien",
            predicate: "Prédicat",
        },
        EntityTimeline: {
            sort_by: "Trier par",
            newest_to_oldest: "Du plus récent au plus ancien",
            oldest_to_newest: "Du plus ancien au plus récent",
            undated: "Non daté",
        },
        EntityViewSelector: {
            list_view: "Vue liste",
            timeline_view: "Vue chronologique",
        },
        ResearchSpaceButton: {
            view_resource_on: "Visualiser la ressource dans ResearchSpace",
        },
        SidePanelHeader: {
            context_explorer: "Explorateur de contexte",
        },
        ThirdLevelTimeline: {
            viewing: "Visualiser",
        },
        ViewMoreDetailsView: {
            back: "Retour",
            properties: "Propriétés",
            type: "Type",
            identifier: "Identifiant",
            sources: "Sources",
        },
    },
};
